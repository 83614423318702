import React from "react"
import { Link } from "gatsby"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import addDays from "date-fns/addDays"
import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import sv from "date-fns/locale/sv"
import Layout from "../components/Layout"
import styles from "./index.module.css"
import SEO from "../components/Seo"
import { getPrice } from "../services/price"
import Footer from "../components/Footer"
import { endOfDay, formatRelative } from "date-fns"
import { RegistrationClosed } from "./anmalan"

export default function Home() {
  const now = new Date()
  const hasPrice = Object.keys(getPrice(new Date())).length > 0

  return (
    <>
      <SEO title="Hem" />
      <Layout>
        <div className={styles.index}>
          <div className={styles.content}>
            <div className={styles.usp}>
              Skippa köphetsen, ta med dig dina vänner och spring reflexbana i
              mörkret i Hisingsparken.
            </div>
            <div className={styles.date}>
              <time dateTime="2024-11-29">29 November 2024</time>
            </div>
            <div className={styles.data}>
              Tuve, Göteborg
              <br />9 km / 2,5 km
            </div>

            <div className={styles.register}>
              {hasPrice ? (
                <Link className={styles.registerLink} to="/anmalan">
                  Gå till anmälan!
                </Link>
              ) : (
                <RegistrationClosed />
              )}
              <br />
              <PriceIncreaseBanner now={now} />
              <News now={now} />
            </div>
          </div>
          <Footer />
        </div>
      </Layout>
    </>
  )
}

const news = [
  [
    parseISO("2024-10-01 19:00:00"),
    (date, now) => (
      <>
        {formatRelative(date, now, { locale: sv })} anordnar vi provlöpning av
        banan,{" "}
        <a href="https://www.facebook.com/events/519752963984071">
          mer information och anmälan på Facebook
        </a>
      </>
    ),
    // Se{" "}
    // <a href="https://www.facebook.com/events/597817105198027/">
    //   vårt evenmang på Facebook
    // </a>{" "}
    // för mer information.
  ],
]

function News({ now }) {
  return (
    <div className="mt-16">
      {news
        .filter(([date]) => date > endOfDay(now))
        .map(([date, text]) => (
          <div className={`${styles.priceIncreaseBanner} max-w-md mx-auto`}>
            {text(date, now)}
          </div>
        ))}
    </div>
  )
}

export function PriceIncreaseBanner({ now }) {
  const { cutoffDate, isLastCutoff } = getPrice(now).standard
  const daysTo = differenceInCalendarDays(parseISO(cutoffDate), now)

  return !isLastCutoff && daysTo < 6 ? (
    <div className={styles.priceIncreaseBanner}>
      Passa på,{" "}
      {daysTo < 1
        ? "imorgon"
        : `från och med ${format(addDays(parseISO(cutoffDate), 1), "EEEE", {
            locale: sv,
          })}`}{" "}
      höjs anmälningsavgiften!
    </div>
  ) : null
}
